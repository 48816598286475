import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type {
  ClientErrorData,
  MotionUpdateResponse,
  MotionResponse,
  MBox,
  MotionCheckResponse,
  MotionViewChangeFilter,
  MBoxPriorityItem,
  OutboundShipmentsMonitorByIds,
  MotionBoxRequest,
  MotionActionBoxRequest,
} from '../models/types'

export const useMotionApi = () => {
  const api = useApi()
  const user = useUserStore()

  const fetchMotionBoxes = async (
    payload: MotionBoxRequest,
  ): Promise<{ data: MotionResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion/boxes`, false, JSON.stringify(payload))

  const fetchActionMotionBoxes = async (
    payload: MotionActionBoxRequest,
  ): Promise<{ data: MotionResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion/action/boxes`, false, JSON.stringify(payload))

  const fetchUpdatedMotionItems = async (
    payload: OutboundShipmentsMonitorByIds,
  ): Promise<{ data: MotionUpdateResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion-view/update`, false, JSON.stringify(payload))

  const checkForNewMotionItems = async (
    payload: MotionViewChangeFilter,
  ): Promise<{ data: MotionCheckResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/motion-view/changepoll`, false, JSON.stringify(payload))

  const fetchSelectedMotionItems = async (payload: {
    priorityItems: MBoxPriorityItem[]
  }): Promise<{ data: MotionUpdateResponse | null; error: ClientErrorData | null }> =>
    await api.request.post(`view/${user.viewId}/search/mboxes-10`, false, JSON.stringify(payload))

  const selectMBox = async (mboxId: number): Promise<{ data: MBox | null; error: ClientErrorData | null }> =>
    await api.request.get<MBox>(`view/${user.viewId}/motion-view/mbox/${mboxId}/select`)

  return {
    fetchMotionBoxes,
    fetchActionMotionBoxes,
    fetchUpdatedMotionItems,
    fetchSelectedMotionItems,
    checkForNewMotionItems,
    selectMBox,
  }
}
