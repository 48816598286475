<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserStore } from '../stores/user'
import { mdiContentSave } from '@mdi/js'
import { isEqual } from 'lodash'
import type { Profile } from '@/api/models/types'
import ModalContainer from '@/shared/components/ModalContainer.vue'
import ModalBody from '@/shared/components/ModalBody.vue'
import ModalFooter from '@/shared/components/ModalFooter.vue'
import FormDivider from '@/shared/components/FormDivider.vue'
import TextInput from '@/shared/components/TextInput.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import DefaultButton from '@/shared/components/DefaultButton.vue'

interface Props {
  updating?: boolean
}

defineProps<Props>()
const open = defineModel<boolean>('open', { required: true })
const userStore = useUserStore()
const errors = ref<boolean[]>([])
const isReadOnly = ref<boolean>(true)
const profile = ref<Profile>({
  firstName: userStore.profile?.firstName || '',
  lastName: userStore.profile?.lastName || '',
  emailAddress: userStore.profile?.emailAddress || '',
  phoneNumber: userStore.profile?.phoneNumber || '',
})

const defaultProfile = () => {
  profile.value.firstName = userStore.profile?.firstName || ''
  profile.value.lastName = userStore.profile?.lastName || ''
  profile.value.emailAddress = userStore.profile?.emailAddress || ''
  profile.value.phoneNumber = userStore.profile?.phoneNumber || ''
}

const close = (): void => {
  errors.value = []
  isReadOnly.value = true
  open.value = false
  defaultProfile()
}

const update = (): void => {
  isReadOnly.value = true
  userStore.updateProfile(profile.value)
}

const initials = computed((): string =>
  profile.value ? `${profile.value.firstName.charAt(0)}${profile.value.lastName.charAt(0)}` : '',
)

const hasChanges = computed((): boolean => !isEqual(profile.value, userStore.profile))
</script>

<template>
  <ModalContainer
    :title="$t('glossary.myProfile')"
    :show-edit="true"
    v-model:open="open"
    v-model:readonly="isReadOnly"
    :updating="updating"
    @close="close()"
  >
    <ModalBody>
      <div class="flex-1">
        <div class="flex justify-center rounded-md pb-6">
          <div class="flex items-center justify-center bg-gray-500 rounded-full w-32 h-32 shadow-md">
            <span class="font-medium text-5xl text-white uppercase">{{ initials }}</span>
          </div>
        </div>
      </div>
      <FormDivider :title="$t('glossary.userDetail', 2)" class="pb-4" />
      <div class="flex-1">
        <div class="flex flex-row items-center justify-center gap-4 pb-4">
          <div class="basis-1/2">
            <TextInput
              :label="$t('glossary.firstName')"
              v-model="profile.firstName"
              v-model:error="errors[0]"
              :readonly="isReadOnly"
              :required="true"
            />
          </div>
          <div class="basis-1/2">
            <TextInput
              :label="$t('glossary.lastName')"
              v-model="profile.lastName"
              v-model:error="errors[1]"
              :readonly="isReadOnly"
              :required="true"
            />
          </div>
        </div>
        <div class="flex flex-row items-center justify-center gap-4 pb-4">
          <div class="basis-1/2">
            <TextInput
              :label="$t('glossary.email')"
              v-model="profile.emailAddress"
              v-model:error="errors[2]"
              :required="true"
              :email="true"
              :readonly="isReadOnly"
            />
          </div>
          <div class="basis-1/2">
            <TextInput :label="$t('glossary.phone')" v-model="profile.phoneNumber" :readonly="isReadOnly" />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="justify-end">
      <PrimaryButton
        v-if="!isReadOnly"
        :text="$t('common.save')"
        v-model:errors="errors"
        :icon="mdiContentSave"
        :disabled="!hasChanges"
        :loading="updating"
        class="mr-2"
        @click="update()"
      />
      <DefaultButton :text="hasChanges ? $t('common.cancel') : $t('common.close')" @click="close()" />
    </ModalFooter>
  </ModalContainer>
</template>
