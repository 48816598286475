<script setup lang="ts">
import { useNavigationStore } from '../stores/navigation'
import { mdiMenuOpen, mdiMenuClose } from '@mdi/js'
import { useLocaleOption, type NavItem } from '@/locales/layout/option'
import { useUserStore } from '@/modules/user/stores/user'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import NavBarItem from '../../../shared/components/NavBarItem.vue'

const option = useLocaleOption()
const navgationStore = useNavigationStore()
const userStore = useUserStore()

const activeNavItems = () => {
  return option.mainNavigation.reduce((result, navItem: NavItem) => {
    if (!navItem.clientConfigRequired || userStore.checkClientSetting(navItem.clientConfigRequired)) {
      result.push(navItem)
    }
    return result
  }, [] as NavItem[])
}
const activeSystemNavItems = () => {
  return option.systemNavigation.reduce((result, navItem: NavItem) => {
    if (!navItem.permissionRequired || userStore.hasPermission(navItem.permissionRequired)) {
      result.push(navItem)
    }
    return result
  }, [] as NavItem[])
}
</script>

<template>
  <aside
    class="fixed top-10 left-0 border-r border-gray-300 transition-width ease-in-out duration-200 bg-white shadow-md"
    :class="[navgationStore.isExpanded ? 'w-64' : 'w-12']"
  >
    <div class="flex h-[calc(100vh-80px)]">
      <ul class="flex flex-1 flex-col">
        <li class="border-b border-gray-300" v-for="(nav, index) in activeNavItems()" :key="index">
          <NavBarItem :title="nav.title" :path="nav.path" :icon="nav.icon" :is-active="navgationStore.isExpanded" />
        </li>
        <li
          class="border-t border-gray-300 mt-auto"
          :class="{ 'mt-auto': index === 0 }"
          v-for="(sys, index) in activeSystemNavItems()"
          :key="index"
        >
          <NavBarItem :title="sys.title" :path="sys.path" :icon="sys.icon" :is-active="navgationStore.isExpanded" />
        </li>
        <li class="border-t border-gray-300" :class="{ 'mt-auto': activeSystemNavItems().length === 0 }">
          <div
            class="border-l-4 border-gray-50 flex p-2 last-of-type:border-white"
            :class="[navgationStore.isExpanded ? 'justify-end' : 'justify-center']"
          >
            <UnstyledButton
              :title="navgationStore.isExpanded ? $t('common.collapse') : $t('common.expand')"
              @click="navgationStore.open(!navgationStore.isExpanded)"
            >
              <SvgIcon :path="navgationStore.isExpanded ? mdiMenuOpen : mdiMenuClose" class="w-6 h-6 fill-gray-700" />
            </UnstyledButton>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>
