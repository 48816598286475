<script setup lang="ts">
import { ref, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { mdiChevronDown } from '@mdi/js'
import { useUserStore } from '@/modules/user/stores/user'
import type { ClientItem } from '../interfaces/search'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'
import SvgIcon from '@/shared/components/SvgIcon.vue'

interface Props {
  title: string
  items: ClientItem[]
  disabled?: boolean
}
const userStore = useUserStore()
const props = defineProps<Props>()
const model = defineModel<string>({ required: true })
const selected = ref<ClientItem>({ key: '', name: '', count: 0 })
const isActive = ref(false)

const setDefaultValue = (): void => {
  if (props.items.length > 0) {
    const item = props.items.find((item) => item.key === model.value)
    if (item) {
      selected.value = item
    } else {
      const client = userStore.clients.find((client) => client.id === model.value)
      if (client) {
        selected.value = { key: client.id, name: client.name, count: 0 }
      }
    }
  }
}

const updateModel = (item: ClientItem) => {
  model.value = item.key
  selected.value = item
  isActive.value = false
}

watch(
  () => props.items,
  () => setDefaultValue(),
)
</script>

<template>
  <div class="relative" v-on-click-outside="() => (isActive = false)">
    <div class="flex items-center border border-gray-300" :class="{ 'border-dotted': disabled }">
      <UnstyledButton
        :title="$t('common.open')"
        class="peer flex w-full bg-transparent p-2 truncate"
        :class="{ 'bg-gray-100 shadow-inner': items.length > 1 && isActive }"
        @click="isActive = !isActive"
        :disabled="disabled"
        v-bind="$attrs"
      >
        <div class="truncate text-sm">
          {{ selected.name }}
        </div>
        <div class="flex-1"></div>
        <div class="text-white text-xs px-1.5 py-0.5 me-1" :class="items.length === 0 ? 'bg-gray-400' : 'bg-gray-700'">
          {{ selected.count }}
        </div>
        <div>
          <SvgIcon
            :path="mdiChevronDown"
            class="transition transform ease-in-out h-5 w-5"
            :class="[isActive ? 'rotate-180' : 'rotate-0', disabled ? 'fill-gray-400' : 'fill-gray-700']"
          />
        </div>
      </UnstyledButton>
    </div>
    <div
      class="z-30 absolute mt-0.5 border border-gray-300 bg-white transition-all transform ease-in-out duration-200 w-full overflow-x-auto max-h-64 focus:outline-none shadow-md"
      :class="isActive ? 'opacity-100' : 'opacity-0 collapse'"
    >
      <ul class="cursor-pointer">
        <li
          class="text-sm truncate hover:bg-gray-100 p-2"
          v-for="(item, index) in items"
          :key="index"
          @click="updateModel(item)"
        >
          <div class="flex justify-between">
            {{ item.name }}
            <span class="bg-gray-700 text-white text-xs px-1.5 py-0.5 me-6">{{ item.count }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
