<script setup lang="ts">
import { computed } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { mdiEyeOutline } from '@mdi/js'
import type { SearchItem } from '@/api/models/types'
import type { ClientItem } from '../interfaces/search'
import SearchResultMenuTable from './SearchResultMenuTable.vue'
import ProgressBar from '@/shared/components/ProgressBar.vue'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue'
import PrimaryButton from '@/shared/components/PrimaryButton.vue'
import SearchResultMenuClient from './SearchResultMenuClient.vue'

interface Props {
  loading: boolean
  items: ClientItem[]
  result?: { [item: string]: SearchItem[] }
}

interface Emits {
  (e: 'change'): void
}

defineOptions({ inheritAttrs: false })
const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const model = defineModel<SearchItem[]>({ required: true })
const active = defineModel<boolean>('active', { required: true })
const client = defineModel<string>('client', { required: true })

const view = () => {
  if (props.result) {
    model.value = props.result[client.value]
    active.value = false
    emits('change')
  }
}

const hasSearchResult = computed(() => {
  if (props.result && props.result[client.value]) {
    return Object.keys(props.result[client.value]).length === 0
  } else {
    return true
  }
})
</script>

<template>
  <div
    class="z-40 absolute border-x border-b border-gray-300 bg-white transition-all transform ease-in-out duration-200 w-full focus:outline-none shadow-md"
    :class="active ? 'opacity-100' : 'opacity-0 collapse'"
    v-on-click-outside="() => (active = false)"
  >
    <ProgressBar :loading="loading" />
    <div class="relative">
      <LoadingSpinner :loading="loading">
        <div class="p-4" v-if="items">
          <SearchResultMenuClient
            :title="$t('common.open')"
            v-model="client"
            :items="items"
            :disabled="items.length === 0"
          />
          <SearchResultMenuTable v-model="client" :result="result" :items="items" :loading="loading" class="pt-2" />
          <div class="flex items-center justify-end pt-4" v-if="client && result">
            <PrimaryButton
              :text="$t('common.view')"
              :icon="mdiEyeOutline"
              @click="view()"
              :disabled="hasSearchResult"
            />
          </div>
        </div>
      </LoadingSpinner>
    </div>
  </div>
</template>
