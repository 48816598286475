import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import type { MBox, MBoxPriorityItem, MotionViewChangeFilter, OutboundShipmentsMonitorByIds } from '@/api/models/types'
import { useMessageStore } from '@/shared/stores/message'
import { useMotionApi } from '@/api/services/motion'
import { ERROR, SUCCESS } from '@/constants'
import { MotionTrackDefault, type MotionTrack } from '@/shared/interfaces/item'
import { useFilterStore } from '@/modules/filter/stores/filter'
import { useSearchStore } from '@/modules/search/stores/search'
import { addDays, formatISO } from 'date-fns'
import { useUserStore } from '@/modules/user/stores/user'

export const useMotionStore = defineStore('motion', () => {
  const api = useMotionApi()
  const userStore = useUserStore()
  const filterStore = useFilterStore()
  const messageStore = useMessageStore()
  const searchStore = useSearchStore()
  const { isSearchActive } = storeToRefs(searchStore)
  const lastKnownChangeVersion = ref<string>('0')
  const trackId = ref<number>(0)
  const items = ref<MBox[]>([])
  const priorityItems = ref<MBoxPriorityItem[]>([])
  const isLoading = ref({
    update: false,
    init: false,
  })

  const refreshMotionItems = async (): Promise<void> => {
    if (!isSearchActive.value) {
      isLoading.value.init = true
      if (filterStore.groupId) {
        await getMotionActionBoxes()
      } else {
        await getMotionBoxes()
      }
      isLoading.value.init = false
    }
  }

  const getMotionBoxes = async (): Promise<void> => {
    const filter = {
      viewFilter: filterStore.viewFilter,
      dateRange: filterStore.dateRange,
    }
    const { data, error } = await api.fetchMotionBoxes(filter)
    if (error) {
      messageStore.showToast(ERROR, error.message)
    }
    if (data && data.items) {
      items.value = data.items
      lastKnownChangeVersion.value = data.changeVersion
      trackId.value = data.trackId
      messageStore.showToast(SUCCESS, `Showing: ${data.items.length} items`)
    }
  }

  const getAction = () => {
    return userStore.kpiSetting?.actionSettings.find((action) => action.groupId === filterStore.groupId)
  }

  const getMotionActionBoxes = async (): Promise<void> => {
    const filter = {
      viewFilter: filterStore.viewFilter,
      dateRange: {
        fromViewDate: filterStore.dateRange.fromViewDate,
        toViewDate: formatISO(addDays(filterStore.dateRange.toViewDate, 1)),
      },
      actionFilter: getAction(),
    }
    const { data, error } = await api.fetchActionMotionBoxes(filter)
    if (error) {
      messageStore.showToast(ERROR, error.message)
    }
    if (data && data.items) {
      items.value = data.items
      lastKnownChangeVersion.value = data.changeVersion
      trackId.value = data.trackId
      messageStore.showToast(SUCCESS, `Showing: ${data.items.length} items`)
    }
  }

  /** Fetch selected items from search result modal */
  const getMotionSelectedItems = async (): Promise<void> => {
    isLoading.value.init = true
    const { data, error } = await api.fetchSelectedMotionItems({ priorityItems: priorityItems.value })
    if (error) {
      messageStore.showToast(ERROR, error.message)
    }
    if (data && data.priorityItems) {
      items.value = data.priorityItems
      lastKnownChangeVersion.value = data.changeVersion
      trackId.value = data.trackId
      messageStore.showToast(SUCCESS, `Showing ${data.priorityItems.length} search selected items`)
    }
    isLoading.value.init = false
  }

  const firstItem = (): MotionTrack | null => {
    if (items.value.length >= 1) {
      return { id: items.value[0].id, index: 0, status: items.value[0].status }
    }
    return null
  }

  const getUpdatedStatus = (updatedItems: MBox[]): { [key: number]: MBox } => {
    return updatedItems.reduce((updatedState: { [key: number]: MBox }, updateItem: MBox) => {
      const index = items.value.findIndex((item: MBox) => item.id === updateItem.id)
      if (index > -1) {
        updatedState[index] = updateItem
      }
      return updatedState
    }, {})
  }

  const updateMotionItems = async (): Promise<void> => {
    const payload: OutboundShipmentsMonitorByIds = {
      fromViewDate: filterStore.dateRange.fromViewDate,
      toViewDate: filterStore.dateRange.toViewDate,
      status: filterStore.viewFilter.status,
      fallbackQueuePriority: 15,
      priorityItems: priorityItems.value,
    }

    const { data, error } = await api.fetchUpdatedMotionItems(payload)
    if (error) {
      messageStore.showToast(ERROR, error.message)
    }
    if (data) {
      const updatedStates = getUpdatedStatus(data.priorityItems)
      for (const [key, value] of Object.entries(updatedStates)) {
        items.value[Number(key)].status = value.status
        items.value[Number(key)].flag = value.flag
        items.value[Number(key)].severity = value.severity
        items.value[Number(key)].mType = value.mType
        items.value[Number(key)].isHidden = value.isHidden
      }
    }
  }

  const checkForNewMotionItems = async (): Promise<void> => {
    const payload: MotionViewChangeFilter = {
      fromViewDate: filterStore.dateRange.fromViewDate,
      toViewDate: filterStore.dateRange.toViewDate,
      status: filterStore.viewFilter.status,
      changeVersion: lastKnownChangeVersion.value,
      trackId: trackId.value,
      lev0ItemCount: items.value.length,
    }

    const { data, error } = await api.checkForNewMotionItems(payload)
    if (error) {
      return
    }
    if (data) {
      lastKnownChangeVersion.value = data.changeVersion
      trackId.value = data.trackId
      if (data.addedItems.length !== 0) {
        // TODO handle as defined (and it's not defined)
        messageStore.showToast(SUCCESS, `There are ${data.addedItems.length} new items`)
      }
    }
  }

  const updateSingle = async (mBoxId: number): Promise<void> => {
    const { data, error } = await api.selectMBox(mBoxId)
    if (error) {
      messageStore.showToast(ERROR, error.message)
    } else if (data) {
      const index = items.value.findIndex((item: MBox) => item.id === mBoxId)
      items.value[index].status = data.status
      items.value[index].flag = data.flag
      items.value[index].severity = data.severity
      items.value[index].isHidden = data.isHidden
    }
  }

  const getIndexOfItem = (id: number): MotionTrack => {
    const index = items.value.findIndex((item: MBox) => item.id === id)
    if (index === -1) return MotionTrackDefault
    return { id, index, status: items.value[index].status }
  }

  const getPreviousRelativeTo = (id: number | null): MotionTrack => {
    if (id === null) return MotionTrackDefault
    const index = items.value.findIndex((item: MBox) => item.id === id)
    if (index == 0) return MotionTrackDefault
    return { id: items.value[index - 1].id, index, status: items.value[index - 1].status }
  }

  const getNextRelativeTo = (id: number | null): MotionTrack => {
    if (id === null) return MotionTrackDefault
    const index = items.value.findIndex((item: MBox) => item.id === id) + 1
    if (index >= items.value.length) return MotionTrackDefault
    return { id: items.value[index].id, index, status: items.value[index].status }
  }

  const getNextFirstViewDateRelativeTo = (id: number | null): MotionTrack => {
    if (id === null) return MotionTrackDefault
    const baseIndex = items.value.findIndex((item: MBox) => item.id === id)
    let i = baseIndex
    if (items.value[i].viewDate && i < items.value.length - 1) i++ // were already at first item on day
    while (!items.value[i].viewDate) {
      i++
      if (i >= items.value.length) {
        return { id: items.value[baseIndex].id, index: baseIndex, status: items.value[baseIndex].status }
      }
    }
    return { id: items.value[i].id, index: i, status: items.value[i].status }
  }

  const getPrevFirstViewDateRelativeTo = (id: number | null): MotionTrack => {
    if (id === null) return MotionTrackDefault
    const baseIndex = items.value.findIndex((item: MBox) => item.id === id)
    let i = baseIndex
    if (items.value[i].viewDate && i > 0) i-- // were already at first item on day
    while (!items.value[i].viewDate) {
      i--
      if (i < 0) return { id: items.value[baseIndex].id, index: baseIndex, status: items.value[i].status }
    }
    return { id: items.value[i].id, index: i, status: items.value[i].status }
  }

  const textOfItems = (selectedItems: { [id: number]: number }): string => {
    const texts = Object.keys(selectedItems).reduce((concat, id) => {
      if (concat.length !== 0) concat += '\n'
      const index = selectedItems[parseInt(id)]
      return concat + items.value[index].label
    }, '')
    return texts
  }

  const getIdsInRange = (fromId: number, toId: number): MotionTrack[] => {
    const range: MotionTrack[] = []
    let iStart = items.value.findIndex((item: MBox) => item.id === fromId)
    let iEnd = items.value.findIndex((item: MBox) => item.id === toId)
    if (iEnd < iStart) {
      const tmp = iEnd
      iEnd = iStart
      iStart = tmp
    }
    for (let i = iStart; i <= iEnd; i++) {
      const item = items.value[i]
      range.push({ id: item.id, index: i, status: item.status })
    }
    return range
  }

  const getAllIds = (): MotionTrack[] => {
    const mapped: MotionTrack[] = []
    items.value.forEach((item, index) => mapped.push({ id: item.id, index, status: item.status }))
    return mapped
  }

  const resetAll = () => {
    lastKnownChangeVersion.value = '0'
    trackId.value = 0
    items.value = []
    priorityItems.value = []
  }

  return {
    items,
    priorityItems,
    isLoading,
    refreshMotionItems,
    updateMotionItems,
    checkForNewMotionItems,
    updateSingle,
    resetAll,
    getIndexOfItem,
    getMotionSelectedItems,
    firstItem,
    getPreviousRelativeTo,
    getNextRelativeTo,
    getNextFirstViewDateRelativeTo,
    getPrevFirstViewDateRelativeTo,
    textOfItems,
    getAllIds,
    getIdsInRange,
  }
})
