<script setup lang="ts">
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import {
  mdiLogin,
  mdiUpdate,
  mdiPencil,
  mdiLoading,
  mdiHistory,
  mdiPencilOff,
  mdiWindowClose,
  mdiInvoiceTextPlus,
  mdiTicketConfirmation,
} from '@mdi/js'
import type { ItemLog } from '@/api/models/types'
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'
import DateTime from './DateTime.vue'

interface Props {
  title: string
  showEdit?: boolean
  loading?: boolean
  updating?: boolean
}

interface Emits {
  (e: 'close'): void
}

defineOptions({ inheritAttrs: false })
defineProps<Props>()
const emits = defineEmits<Emits>()
const open = defineModel<boolean>('open', { required: true })
const read = defineModel<boolean>('readonly')
const log = defineModel<ItemLog[] | null>('log')
const isHistoryOpen = ref(false)

const getIcon = (icon: string) => {
  switch (icon) {
    case 'Update':
      return mdiUpdate
    case 'Create':
      return mdiInvoiceTextPlus
    case 'Login':
      return mdiLogin
    case 'Confirm':
      return mdiTicketConfirmation
  }
}

const onClose = () => {
  emits('close')
}
</script>

<template>
  <div
    class="fixed z-40 left-0 top-0 bg-gray-900 transform transition-opacity ease-in-out duration-300 h-full w-full"
    :class="open ? 'opacity-60' : 'opacity-0 collapse'"
  ></div>
  <div
    class="fixed z-40 left-0 top-0 flex transform transition-all ease-in-out duration-300 justify-center items-center w-full h-full"
    :class="open ? 'translate-y-0 opacity-100 overflow-y-auto' : 'overflow-y-initial translate-y-8 opacity-0 collapse'"
  >
    <div
      v-if="open"
      class="w-full max-h-lvh max-w-2xl bg-white shadow-lg"
      v-on-click-outside="() => onClose()"
      v-bind="$attrs"
    >
      <div class="relative py-4 mx-4">
        <div class="inline-flex text-nowrap items-center absolute inset-0">
          <div class="font-medium text-lg text-gray-700 pr-2">{{ title }}</div>
          <div class="border-t border-gray-200 w-full"></div>
        </div>
        <div class="flex items-center justify-end relative">
          <template v-if="log && log.length !== 0">
            <div class="relative" v-on-click-outside="() => (isHistoryOpen = false)">
              <UnstyledButton
                :title="$t('glossary.changeLog')"
                class="border-l border-y border-gray-300 text-sm p-2 hover:bg-gray-100 hover:shadow-inner"
                :class="[isHistoryOpen ? 'shadow-inner bg-gray-100' : 'bg-white']"
                @click="isHistoryOpen = !isHistoryOpen"
              >
                <SvgIcon :path="mdiHistory" class="fill-gray-700 w-5 h-5" />
              </UnstyledButton>
              <div
                class="z-50 absolute right-0 border border-gray-300 mt-0.5 text-nowrap bg-white transform transition-opacity ease-in-out duration-200 shadow-md"
                :class="[isHistoryOpen ? 'opacity-100' : 'opacity-0 invisible']"
              >
                <ul class="px-4">
                  <li class="flex items-center justify-start relative" v-for="(item, index) in log" :key="index">
                    <span class="top-0 left-3 absolute w-[1px] h-full bg-gray-200"></span>
                    <div class="relative bg-white">
                      <SvgIcon :path="getIcon(item.actionIcon)" class="fill-gray-700 w-6 h-6" />
                    </div>
                    <div class="ml-1 justify-content items-center p-2">
                      <div class="flex-auto text-xs">
                        <div class="font-bold">{{ item.performedByUser }}</div>
                        <div>
                          {{ $t('glossary.' + item.actionLabelKey) }}:
                          <DateTime :value="item.dateTime" :seconds="true" />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template v-if="showEdit">
            <UnstyledButton
              :title="read ? $t('common.edit') : $t('common.cancel')"
              class="border-l border-y border-gray-300 text-sm p-2 hover:bg-gray-100 hover:shadow-inner"
              :class="[read ? 'bg-white' : 'shadow-inner bg-gray-100']"
              :disabled="loading || updating"
              @click="read = !read"
            >
              <SvgIcon v-if="loading || updating" :path="mdiLoading" class="animate-spin fill-gray-700 w-5 h-5" />
              <SvgIcon v-else :path="read ? mdiPencil : mdiPencilOff" class="fill-gray-700 w-5 h-5" />
            </UnstyledButton>
          </template>
          <UnstyledButton
            :title="$t('common.close')"
            class="peer bg-white border border-gray-300 text-sm p-2 enabled:hover:bg-gray-100 hover:shadow-inner"
            @click="onClose()"
          >
            <SvgIcon :path="mdiWindowClose" class="w-5 h-5" />
          </UnstyledButton>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
