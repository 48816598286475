<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useVersionStore } from '../stores/version'
import { PRODUCTION, BUILD_CHECK_DURATION } from '@/constants'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'

const mode = import.meta.env.MODE
const prod = import.meta.env.PROD
const toggle = ref<boolean>(false)
const intervalId = ref<NodeJS.Timeout>()
const versionStore = useVersionStore()
const currentVersion = ref<string>(__APP_VERSION__)
const buildId = ref<number>(0)

const fetchVersionUpdate = (): void => {
  intervalId.value = setInterval(() => versionStore.fetchVersion(), BUILD_CHECK_DURATION)
}

const setInitialVersion = async (): Promise<void> => {
  await versionStore.fetchVersion()
  buildId.value = versionStore.buildId
}

onMounted(() => fetchVersionUpdate())

onUnmounted(() => clearInterval(intervalId.value))

setInitialVersion()
</script>

<template>
  <span v-if="!prod" class="capitalize font-bold bg-gray-700 text-xs text-nowrap px-2 py-1">
    {{ mode }}
  </span>
  <UnstyledButton
    v-else
    :title="$t('common.expand')"
    class="capitalize font-bold bg-gray-700 text-xs text-secondary-light text-nowrap px-2 py-1"
    :class="{ 'cursor-pointer hover:bg-gray-800': buildId }"
    @click="toggle = !toggle"
  >
    {{ mode === PRODUCTION ? $t('glossary.version') : mode }}
  </UnstyledButton>
  <span class="font-bold text-nowrap bg-gray-500 text-secondary-light text-xs px-2 py-1">
    {{ currentVersion }}
  </span>
  <template v-if="buildId">
    <span
      class="font-bold text-xs text-secondary-light text-nowrap bg-gray-700 transition-opacity ease-in duration-200 px-2 py-1"
      :class="[toggle ? 'opacity-100' : 'opacity-0']"
    >
      {{ $t('glossary.build') }}
    </span>
    <span
      class="font-bold text-xs text-nowrap bg-gray-500 text-white transition-opacity ease-in duration-200 me-2 px-2 py-1"
      :class="[toggle ? 'opacity-100' : 'opacity-0']"
    >
      {{ buildId }}
    </span>
  </template>
</template>
