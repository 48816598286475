// Sourced namespace models
// NB This file is auto generated, do not manually alter!

  import type { CallOffRecipient } from "./types";
  import type { DimensionBlock } from "./types";
  import type { OrderLine } from "./types";
  import type { DetailsSubSection } from "./types";
  import type { DetailsSubSectionGeneral } from "./types";
  import type { Dimension } from "./types";
  import type { MBoxFilter } from "./types";
  import type { OrderLineExtended } from "./types";
  import type { RecipientResult } from "./types";
  import type { ServiceRequestFunctions } from "./types";
  import type { User } from "./types";
  import type { UserClientSettingsSetupResponse } from "./types";
  import type { UserListRequest } from "./types";
  import type { Validation } from "./types";


  export const DefaultCallOffRecipient : CallOffRecipient = {
    identifier: undefined,
    emailAddress: undefined,
    mobileNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    address1: undefined,
    address2: undefined,
    zipCode: undefined,
    city: undefined,
    countryId: undefined,
    languageId: undefined,
    doorAccessCode: undefined,
  }

  export const DefaultDimensionBlock : DimensionBlock = {
    titleHint: '',
    labels: [],
  }

  export const DefaultOrderLine : OrderLine = {
    id: 0,
    customerOrderNo: undefined,
    warehouseOrderNo: undefined,
    shipmentNo: '',
    lineNumber: '',
    articleNo: '',
    name: '',
    orderedQuantity: undefined,
    pickedQuantity: undefined,
    canceledQuantity: undefined,
    returnedQuantity: undefined,
    lineType: 0,
  }

  export const DefaultDetailsSubSection : DetailsSubSection = {
    position: 0,
    dataGroup: '',
    blocks: [],
  }

  export const DefaultDetailsSubSectionGeneral : DetailsSubSectionGeneral = {
    position: 0,
    dataGroup: '',
    blocks: [],
    dimensions: DefaultDimensionBlock,
    carrierId: 0,
    shipmentGroupNo: undefined,
    shipmentGroupCarrierId: undefined,
    shipmentGroupObjectId: undefined,
    shipmentCarrierTrackerLink: undefined,
    mType: 0,
  }

  export const DefaultDimension : Dimension = {
    number: 0,
    id: undefined,
    name: undefined,
    valueId: undefined,
  }

  export const DefaultMBoxFilter : MBoxFilter = {
    fromViewDate: '',
    toViewDate: '',
    carrierId: undefined,
    countryId: undefined,
    zoneId: undefined,
    serviceGroupId: undefined,
    dimension1Id: undefined,
    dimension2Id: undefined,
    dimension3Id: undefined,
    status: [],
  }

  export const DefaultOrderLineExtended : OrderLineExtended = {
    id: 0,
    customerOrderNo: undefined,
    warehouseOrderNo: undefined,
    shipmentNo: '',
    lineNumber: '',
    articleNo: '',
    name: '',
    orderedQuantity: undefined,
    pickedQuantity: undefined,
    canceledQuantity: undefined,
    returnedQuantity: undefined,
    lineType: 0,
    carrierReferenceNo: '',
    carrierId: '',
    carrierServiceCode: '',
    shipmentDate: undefined,
    customerOrderLineNumber: '',
    shipmentLineNumber: '',
    supplierArticleNo: '',
    customerArticleNo: '',
    description: '',
    category1: '',
    category2: '',
    shipmentLineReference: '',
    noOfPackages: undefined,
    unit: '',
    price: undefined,
    discount: undefined,
    tax: undefined,
    freightCost: undefined,
    totalPrice: undefined,
    totalDiscount: undefined,
    totalTax: undefined,
    totalFreightCost: undefined,
    currency: '',
    weight: undefined,
    totalWeight: undefined,
    weightUnit: '',
    volume: undefined,
    totalVolume: undefined,
    volumeUnit: '',
    productUrl: '',
    imageUrl: '',
    dangerousGoods: '',
    typeOfGoods: '',
    typeOfTransport: '',
    plannedDispatchDate: undefined,
    receivedQuantity: undefined,
    insertByUserFullname: '',
    updateByUserFullname: '',
    insertDate: '',
    updateDate: '',
    log: [],
  }

  export const DefaultRecipientResult : RecipientResult = {
    mBoxId: 0,
    ourboxesIdentifier: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    identifier: '',
    address1: '',
    address2: '',
    zipCode: '',
    city: '',
    country: undefined,
    doorAccessCode: '',
    languageId: undefined,
  }

  export const DefaultServiceRequestFunctions : ServiceRequestFunctions = {
    extendAccessPointDays: false,
    changeName: false,
    changeAddress: false,
    changePhone: false,
    changeEmail: false,
    cancelShipment: false,
    changeDoorAccessCode: false,
    leaveOutsideDoor: false,
    knockOnDoor: false,
    noIdRequirement: false,
    messageToDriver: false,
    changeLockerLocation: false,
    newDeliveryAttempt: false,
  }

  export const DefaultUser : User = {
    userId: undefined,
    firstName: '',
    lastName: '',
    organization: '',
    description: '',
    userRoleId: 0,
    userTypeId: 0,
    clientKey: '',
    phoneNumber: '',
    emailAddress: '',
    identityProvider: '',
    isActive: false,
    insertDate: '',
    updateDate: '',
    lastLoginDate: '',
    lastActivationCreatedDate: '',
    lastActivationConfirmDate: '',
    updateByUserFullname: '',
    createdByUserFullname: '',
    createInvite: false,
    log: [],
  }

  export const DefaultUserClientSettingsSetupResponse : UserClientSettingsSetupResponse = {
    userProfileActionHint: undefined,
    accessToken: undefined,
    errorData: undefined,
    rct: undefined,
    clientKey: '',
    webAppsEntryPoints: [],
    countries: [],
    languages: [],
    dimensions: [],
    applicationSettings: [],
    clientSettings: {},
    userSettings: {},
    userViewSettings: {},
    userViewFilters: undefined,
    userLimitations: {},
    userActionGroups: [],
    kpiSetting: undefined,
    userClientPermission: undefined,
    serviceGroups: [],
    carriers: [],
    zipZones: [],
  }

  export const DefaultUserListRequest : UserListRequest = {
    clientKey: undefined,
    freeTextSearch: undefined,
    userCategory: undefined,
    sortBy: '',
  }

  export const DefaultValidation : Validation = {
    isValid: false,
    mType: undefined,
    notValidReason: '',
  }

