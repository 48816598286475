export const INFO = 'info'
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const ERROR = 'error'
export const STATUS = 'Status'
export const POLL_MIN = 10
export const POLL_MAX = 60
export const PRODUCTION = 'production'
export const REFRESH_MOTION = 'refresh-motion'
export const UPDATE_MOTION = 'update-motion'
export const SCROLL_MOTION = 'scroll-motion'
export const REFRESH_HOME = 'refresh-home'
export const UPDATE_HOME = 'update-home'
export const REFRESH_ACTION = 'refresh-action'
export const MESSAGE_DURATION = 5000
export const BUILD_CHECK_DURATION = 300000 // i.e. 5 min
export const DASHBOARD_UPDATE_DURATION = 60000
export const SYS_MAINT_CHECK_DURATION_LONG = 300000 // i.e. 5 min
export const SYS_MAINT_CHECK_DURATION_SHORT = 600000
export const SYS_MAINT_NOTIF_INTERVAL = 2 // i.e. 2 min

export const enum SIunit {
  Weight = 0,
  Volume = 1,
}
