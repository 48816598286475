import { ref } from 'vue'
import { defineStore } from 'pinia'
import { formatISO, subDays } from 'date-fns'
import { useUserStore } from '@/modules/user/stores/user'
import type { UserViewFilters, DateRange, ViewFilter } from '@/api/models/types'
import { ApplicationViewKey } from '@/api/models/definitions'

export const useFilterStore = defineStore('filter', () => {
  const userStore = useUserStore()

  const defaulDateRange = ref<DateRange>({
    fromViewDate: formatISO(new Date().setHours(0, 0, 0)),
    toViewDate: formatISO(new Date().setHours(0, 0, 0)),
  })

  const defaultFilter = ref<ViewFilter>({
    carrierId: undefined,
    countryId: undefined,
    serviceGroupId: undefined,
    zipZoneId: undefined,
    dimension1Id: undefined,
    dimension2Id: undefined,
    dimension3Id: undefined,
    status: [1, 2, 3, 4, 5, 6, 7],
  })

  const dateRange = ref<DateRange>({
    fromViewDate: formatISO(new Date().setHours(0, 0, 0)),
    toViewDate: formatISO(new Date().setHours(0, 0, 0)),
  })

  const viewDateRange = ref<DateRange>({
    fromViewDate: formatISO(new Date().setHours(0, 0, 0)),
    toViewDate: formatISO(new Date().setHours(0, 0, 0)),
  })

  const actionDateRange = ref<DateRange>({
    fromViewDate: formatISO(new Date().setHours(0, 0, 0)),
    toViewDate: formatISO(new Date().setHours(0, 0, 0)),
  })

  const viewFilter = ref<ViewFilter>({
    carrierId: undefined,
    countryId: undefined,
    serviceGroupId: undefined,
    zipZoneId: undefined,
    dimension1Id: undefined,
    dimension2Id: undefined,
    dimension3Id: undefined,
    status: [1, 2, 3, 4, 5, 6, 7],
  })

  const groupId = ref<number>()

  const setUserFilterSettings = (userViewSettings: UserViewFilters | undefined): void => {
    if (userViewSettings) {
      switch (userStore.viewId) {
        case ApplicationViewKey.Agent:
        case ApplicationViewKey.Outbound: {
          const {
            zoneId,
            carrierId,
            countryId,
            serviceGroupId,
            selectNumberOfDays,
            dimension1Id,
            dimension2Id,
            dimension3Id,
            status,
          } = userViewSettings.defaultOutbound
          if (selectNumberOfDays) {
            defaulDateRange.value.fromViewDate = formatISO(subDays(new Date().setHours(0, 0, 0), selectNumberOfDays))
          }
          defaulDateRange.value.toViewDate = formatISO(new Date().setHours(0, 0, 0))
          defaultFilter.value.carrierId = carrierId
          defaultFilter.value.countryId = countryId
          defaultFilter.value.serviceGroupId = serviceGroupId
          defaultFilter.value.zipZoneId = zoneId
          defaultFilter.value.dimension1Id = dimension1Id
          defaultFilter.value.dimension2Id = dimension2Id
          defaultFilter.value.dimension3Id = dimension3Id
          defaultFilter.value.status = status
          viewDateRange.value = { ...defaulDateRange.value }
          actionDateRange.value = { ...defaulDateRange.value }
          viewFilter.value = { ...defaultFilter.value }
          groupId.value = undefined
          break
        }
      }
    }
  }

  const clearAll = (): void => {
    viewFilter.value.carrierId = undefined
    viewFilter.value.countryId = undefined
    viewFilter.value.serviceGroupId = undefined
    viewFilter.value.zipZoneId = undefined
    viewFilter.value.dimension1Id = undefined
    viewFilter.value.dimension2Id = undefined
    viewFilter.value.dimension3Id = undefined
  }

  return {
    viewFilter,
    defaultFilter,
    defaulDateRange,
    dateRange,
    viewDateRange,
    actionDateRange,
    groupId,
    setUserFilterSettings,
    clearAll,
  }
})
