import { useApi } from '..'
import { useUserStore } from '@/modules/user/stores/user'
import type { ClientErrorData, SearchItemResult } from '../models/types'

export const useSearchApi = () => {
  const api = useApi()
  const user = useUserStore()

  const getSearchResult = async (
    query: string,
  ): Promise<{ data: SearchItemResult | null; error: ClientErrorData | null }> =>
    await api.request.get<SearchItemResult>(`view/${user.viewId}/search?query=${query}`, false)

  return { getSearchResult }
}
