<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useSearchStore } from '../stores/search'
import { useUserStore } from '@/modules/user/stores/user'
import { useMotionStore } from '@/modules/motion/stores/motion'
import { useClientStore } from '@/modules/client/store/client'
import { useThemeStore } from '@/shared/stores/theme'
import { useFilterStore } from '@/modules/filter/stores/filter'
import { useSideMenuStore } from '@/modules/side-menu/stores/side-menu'
import { REFRESH_HOME, REFRESH_MOTION, SCROLL_MOTION, WARNING } from '@/constants'
import { useMessageStore } from '@/shared/stores/message'
import router from '@/router'
import EventBus from '@/event-bus'
import SearchBarInput from './SearchBarInput.vue'
import SearchResultMenu from './SearchResultMenu.vue'
import type { SearchItem } from '@/api/models/types'

const searchStore = useSearchStore()
const motionStore = useMotionStore()
const userStore = useUserStore()
const themeStore = useThemeStore()
const clientStore = useClientStore()
const filterStore = useFilterStore()
const sideMenuStore = useSideMenuStore()
const messageStore = useMessageStore()
const { t } = useI18n()
const { viewId, views } = storeToRefs(userStore)
const { searchResult, isSearchLoading, searchQuery, filterText, isSearchActive } = storeToRefs(searchStore)
const { priorityItems } = storeToRefs(motionStore)
const { clientKey } = storeToRefs(clientStore)
const { viewFilter } = storeToRefs(filterStore)
const isSearchMenuActive = ref<boolean>(false)
const isFocus = ref<boolean>(false)
const selectedResult = ref<SearchItem[]>([])
const selectedClient = ref<string>(clientKey.value)
const searchText = ref<string>('')

const refreshMotion = (): void => {
  EventBus.emit(REFRESH_MOTION)
}

const refreshHome = (): void => {
  EventBus.emit(REFRESH_HOME)
}

const scrollToTop = () => {
  EventBus.emit(SCROLL_MOTION)
}

const getSearchResult = async () => {
  if (searchText.value.length > 3 && searchText.value.length <= 1000) {
    isSearchMenuActive.value = true
    searchStore.getSearchResult(searchText.value.trim())
  } else {
    messageStore.showToast(WARNING, t('message.searchError'))
  }
}

const clearResult = (): void => {
  searchText.value = ''
}

const openResult = (): void => {
  if (searchText.value === searchQuery.value && searchResult.value) {
    isSearchMenuActive.value = true
  }
}

const updateClient = async () => {
  await userStore.refreshUserSettings(selectedClient.value)
  themeStore.setDefaultTheme(viewId.value, views.value)
  refreshMotion()
  refreshHome()
}

const getMotionSelectedItems = async () => {
  priorityItems.value = selectedResult.value.map((item) => {
    return { id: item.mBoxId }
  })
  viewFilter.value.status = [1, 2, 3, 4, 5, 6, 7]
  await motionStore.getMotionSelectedItems()
}

const checkClient = async (): Promise<void> => {
  if (clientKey.value !== selectedClient.value) {
    await updateClient()
  }
}

const checkCurrentRoute = async (): Promise<void> => {
  if (router.currentRoute.value.name === 'dashboard') {
    await router.push('/motion-view')
  }
}

const viewSearchResult = async (): Promise<void> => {
  isSearchActive.value = true
  filterText.value = searchText.value
  sideMenuStore.close()
  scrollToTop()
  await checkClient()
  await checkCurrentRoute()
  await getMotionSelectedItems()
}

const getClientModel = (key: string, count: number) => {
  const client = userStore.clients.find((client) => client.id === key)
  if (client) {
    return { key: client.id, name: client.name, count: count }
  } else {
    return { key: 'NoKey', name: 'Unknown', count: 0 }
  }
}

const getClientItems = () => {
  if (searchResult.value) {
    return Object.entries(searchResult.value).map(([key, value]) => {
      return getClientModel(key, value.length)
    })
  } else {
    selectedClient.value = clientKey.value
    return [getClientModel(clientKey.value, 0)]
  }
}

watch(isSearchActive, () => {
  if (!isSearchActive.value) {
    isFocus.value = false
    isSearchActive.value = false
    isSearchMenuActive.value = false
    selectedResult.value = []
    searchResult.value = undefined
  }
})

watch(clientKey, () => {
  selectedClient.value = clientKey.value
})
</script>

<template>
  <div class="relative">
    <SearchBarInput
      v-model="searchText"
      v-model:active="isSearchMenuActive"
      v-model:focus="isFocus"
      @change="getSearchResult()"
      @clear="clearResult()"
      @open="openResult()"
    />
    <SearchResultMenu
      v-model="selectedResult"
      v-model:active="isSearchMenuActive"
      v-model:client="selectedClient"
      :items="getClientItems()"
      :result="searchResult"
      :loading="isSearchLoading"
      @change="viewSearchResult()"
    />
  </div>
</template>
